import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import {
  MobileDrawer,
  Popover,
  IconButton,
  ButtonSize,
} from 'wix-ui-tpa/cssVars';
import Button from '../../Components/Button';
import { classes, st } from './ManageButton.st.css';
import {
  ActionMenuConfiguration,
  BookingsGroup,
  OnActionClickType,
} from '../../../../types';
import { handleWithStopPropagation } from '../../../../utils';
import { ActionsMenuDataHooks, ManageButtonDataHooks } from '../datahooks';
import Text, { TextType } from '../../Components/Text';
import { useGroupHeaderGetters } from '../../../../core/hooks/useGroupHeaderGetter';
import { getMenuActionsConfiguration } from './MenuConfiguration';
import { ExperimentsConsts } from '../../../../consts/experiments';

export type ManageButtonProps = {
  group: BookingsGroup;
  isManageButtonOpen?: boolean;
};

export const ManageButton: React.FC<ManageButtonProps> = ({
  group,
  isManageButtonOpen,
}) => {
  const { isMobile, isRTL } = useEnvironment();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);
  const [actionsConfig, setActionsConfig] = useState<
    ActionMenuConfiguration[] | null
  >(null);

  useEffect(() => {
    const onActionClick: OnActionClickType = (type, options) => {
      setLoading(options?.showManageButtonLoader ?? false);
      setShown(false);
    };
    setActionsConfig(getMenuActionsConfiguration(group, onActionClick));
  }, [group.allowedActions, group.bookings?.[0]?.bookingId]);

  if (!actionsConfig) {
    return null;
  }

  const renderButton = (
    onClick: React.MouseEventHandler,
    size?: ButtonSize,
  ) => {
    return (
      <Button
        upgrade
        loading={loading}
        data-hook={ManageButtonDataHooks.Button}
        className={classes.manageButton}
        onClick={(e) => handleWithStopPropagation(e, onClick)}
        size={size}
      >
        {t('app.my-bookings-widget.manage-actions.button.label')}
      </Button>
    );
  };

  const renderMenuActions = () => {
    return (
      <div
        onClick={handleWithStopPropagation}
        className={classes.actionsMenuContainer}
        data-hook={ActionsMenuDataHooks.ActionsMenuContainer}
      >
        {actionsConfig.map((actionConfig) => actionConfig.Component)}
      </div>
    );
  };

  const drawerAndPopoverProps: DrawerAndPopoverProps = {
    group,
    renderButton,
    shown: isManageButtonOpen ? true : shown,
    setShown,
    renderMenuActions,
  };

  return (
    <div
      className={st(classes.root, { isMobile, isRTL })}
      data-hook={ManageButtonDataHooks.Main}
    >
      {isMobile ? (
        <DrawerManageButton {...drawerAndPopoverProps} />
      ) : (
        <PopoverManageButton {...drawerAndPopoverProps} />
      )}
    </div>
  );
};

export interface DrawerAndPopoverProps {
  group: BookingsGroup;
  renderMenuActions: () => React.ReactNode;
  renderButton: (
    onClick: React.MouseEventHandler,
    size?: ButtonSize,
  ) => React.ReactNode;
  shown: boolean;
  setShown: (shown: boolean) => void;
}

export const PopoverManageButton: React.FC<DrawerAndPopoverProps> = ({
  group,
  renderButton,
  shown,
  setShown,
  renderMenuActions,
}) => {
  const { isRTL } = useEnvironment();
  const { t } = useTranslation();

  const { experiments } = useExperiments();

  const isMyBookingsA11yEnabled = experiments.enabled(
    ExperimentsConsts.MyBookingsA11y,
  );
  return (
    <div className={classes.root} onClick={handleWithStopPropagation}>
      <Popover
        placement={isRTL ? 'right-start' : 'left-start'}
        showArrow={false}
        data-hook={ManageButtonDataHooks.PopoverWrapper}
        shown={shown}
        className={classes.popover}
        onClickOutside={(e: React.MouseEvent) =>
          handleWithStopPropagation(e, () => setShown(false))
        }
      >
        <Popover.Element>
          {renderButton(() => setShown(!shown))}
        </Popover.Element>
        <Popover.Content>
          <>
            {isMyBookingsA11yEnabled && (
              <div className={classes.srOnly}>
                {t(
                  'app.my-bookings-widget.booking-details.manage-button.aria-click-message',
                )}
              </div>
            )}
            {renderMenuActions()}
          </>
        </Popover.Content>
      </Popover>
    </div>
  );
};

export const DrawerManageButton: React.FC<DrawerAndPopoverProps> = ({
  group,
  renderButton,
  shown,
  setShown,
  renderMenuActions,
}) => {
  const { getTitle, getSubtitle } = useGroupHeaderGetters();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const isMyBookingsA11yEnabled = experiments.enabled(
    ExperimentsConsts.MyBookingsA11y,
  );

  const renderMobileMenuHeader = () => {
    if (!isMobile) {
      return null;
    }

    return (
      <div
        data-hook={ManageButtonDataHooks.MobileHeaderContainer}
        className={classes.groupMobileHeader}
      >
        <IconButton
          data-hook={ManageButtonDataHooks.MobileHeaderCloseButton}
          icon={<Close />}
          className={classes.closeButton}
          onClick={() => setShown(false)}
          {...(isMyBookingsA11yEnabled
            ? {
                'aria-label': t(
                  'app.my-bookings-widget.booking-details.modal.aria-close-button',
                ),
              }
            : {})}
        />
        <Text
          data-hook={ManageButtonDataHooks.MobileHeaderTitle}
          type={TextType.Primary}
          tagName="p"
        >
          {getTitle(group)}
        </Text>
        <Text
          data-hook={ManageButtonDataHooks.MobileHeaderSubtitle}
          type={TextType.Secondary}
          tagName="p"
        >
          {getSubtitle(group)}
        </Text>
      </div>
    );
  };

  return (
    <div onClick={handleWithStopPropagation}>
      {renderButton(() => setShown(true), ButtonSize.small)}
      <MobileDrawer
        isOpen={shown}
        onRequestClose={() => setShown(false)}
        data-hook={ManageButtonDataHooks.MobileDrawer}
        className={classes.mobileDrawer}
      >
        <div className={classes.drawerContent}>
          {renderMobileMenuHeader()}
          {renderMenuActions()}
        </div>
      </MobileDrawer>
    </div>
  );
};
